@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.done-section-clients {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.done-section-clients-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-align: center;
  flex-basis: 100%;
}

.done-section-clients-images {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 3.7rem;
  display: flex;
}

.done-section-clients-image {
  flex-basis: 45%;
}

.done-section-clients-image img {
  width: 100%;
}

@media only screen and (width >= 992px) {
  .done-section-clients-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-5);
    max-width: 725px;
  }

  .done-section-clients-images {
    flex-wrap: nowrap;
    width: 100%;
    padding-top: 5.6rem;
  }

  .done-section-clients-image {
    flex-basis: 20%;
  }

  .done-section-clients-image img {
    width: 100%;
  }

  .done-section-clients-image-stgobain img {
    height: 30%;
  }
}

/*# sourceMappingURL=index.d1bacb71.css.map */
